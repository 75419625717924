.field {
    position: relative;
    width: 100%;
    padding: 8px 0;
    transition: 0.3s;

    &__label {
        display: block;
        margin: 0;
        color: #27173e;
        font-weight: 500;
        font-size: 11px;
        line-height: 1.2em;
        transition: 0.5s;
    }

    &__hint,
    &__error {
        color: #a9abad;
        font-size: 11px;
        text-align: left;
        transition: 0.3s linear;
    }

    &__hint {
        opacity: 1;

        &_error {
            opacity: 0;
        }
    }

    &__error {
        position: absolute;
        color: #f44336;
        opacity: 0;

        &_show {
            opacity: 1;
        }
    }

    &__input-wrapper {
        position: relative;
        box-sizing: border-box;
    }

    &__input,
    input,
    textarea,
    select {
        display: block;
        box-sizing: border-box;
        width: 100%;
        height: 40px;
        padding: 0;
        color: #27173e;
        font-size: 15px;
        line-height: 1.5;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #dcdce9;
        border-radius: 0;
        transition: 0.3s;

        &:focus {
            border-bottom-color: #6236ff;
            outline: none !important;
            box-shadow: inset 0 -1px 0 0 #6236ff;
        }
    }

    &_focused {
        .field__label {
            color: #6236ff !important;
        }

        &__input,
        input,
        textarea,
        select {
            border-color: #6236ff;
            box-shadow: inset 0 -1px 0 0 #6236ff;
        }
    }

    &_error {
        .field__label {
            color: #f44336;
        }

        &__input,
        input,
        textarea,
        select {
            border-color: #f44336;
        }
    }
}
