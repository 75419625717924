.head {
    position: relative;

    .title {
        margin: 0;
        padding: 5px;
        color: var(--gray);
        font-size: 1em;
    }

    .info {
        position: relative;

        .circle {
            box-sizing: border-box;
            width: 160px;
            height: 160px;
            margin: 20px auto;
            padding: 20px;
            text-align: center;
            background-color: #f0f0f0;
            border-radius: 50%;

            .balance {
                margin: 18px 0;
                font-size: 1.4em;
            }

            > div {
                padding-right: 6px;
            }

            svg {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                margin: 0 auto;

                circle {
                    transition: 0.5s;
                    fill: rgb(0 0 0 / 0%);
                    stroke: #eee;
                    stroke-width: 15;
                }

                // income
                circle:nth-child(2n) {
                    fill: rgb(0 0 0 / 0%);
                    stroke: #30bae7;
                    stroke-width: 15;
                }

                // expense
                circle:nth-child(3n) {
                    fill: rgb(0 0 0 / 0%);
                    stroke: red;
                    stroke-width: 15;
                }
            }
        }
    }
}
