.page {
    min-height: 640px;
}

.filter {
    // background-color: #d0d0d020;
    text-align: left;

    button {
        margin: 8px;
        padding: 3px 10px;
        font-size: 11px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 10px;

        &.active {
            background-color: #2dacf9;
            border-color: #2dacf9;
        }

        &:focus {
            outline: none;
        }
    }
}
