.txList {
    margin: 0;
    padding: 0;

    // border-top: 1px solid #d0d0d0;
}

.txItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    height: 44px;
    padding: 7px 12px;
    color: #060606;
    list-style: none;
    border-bottom: 1px solid #d0d0d0;

    .txItem__left {
        font-size: 12px;
        text-align: left;
    }

    .txItem__first {
        margin: 0;
    }

    .txItem__second {
        font-size: 10px;
    }

    .txItem__amount {
        font-weight: 600;
        font-size: 12px;
        text-align: right;
    }

    .txItem__date {
        margin: 2px 0;
        color: #808080;
        font-size: 10px;
        text-align: right;
    }
}

.empty {
    color: #a0a0a0;
}
