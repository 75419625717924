.scan-qr-page {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100000;
    display: flex;
    flex-flow: column;
    max-height: 100vh;
    background-color: #000;

    &__scanner {
        flex: 0 1 100%;
        max-height: 100%;
    }
}
