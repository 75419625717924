.section {
    margin: 16px 0;
    padding: 0 16px;

    &_full {
        padding: 0;

        &__title {
            padding: 6px 16px;
        }
    }

    &__title {
        margin: 0;
        padding: 6px 0;
        color: #27173e;
        font-weight: 500;
        font-size: 15px;
        text-align: left;
    }
}
