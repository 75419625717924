.swipeWidget {
    position: relative;
    margin: 6px -12px -6px;

    .prev,
    .next {
        position: absolute;
        top: 0;
        display: block;
        width: 40px;
        height: 100%;

        svg {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 23px;
            margin: auto;
        }
    }

    .prev {
        left: 0;
    }

    .next {
        right: 0;
    }
}

.swipe {
    position: relative;
    overflow: hidden;
    visibility: hidden;

    > .swipe-wrap {
        position: relative;
        overflow: hidden;
    }
}

.swipeItem {
    position: relative;
    top: 0;
    float: left;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 12px;
    overflow: hidden;
}
