.root-loader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    width: 100%;
    height: 100%;
    background: #fff;
    place-content: center;
}

.loader {
    box-sizing: border-box;
    padding: 10px;
    background: transparent;
}

.loader__text {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2em;
    margin: auto;
    line-height: 2em;
    text-align: center;

    span {
        display: inline-block;
        margin: 0 5px;
        color: #27173e;
        font-family: sans-serif;
        @for $i from 0 through 10 {
            &:nth-child(#{$i + 1}) {
                animation: blur-text 1.5s (#{$i/10}) + s infinite linear alternate;
            }
        }
    }
}

@keyframes blur-text {
    0% {
        filter: blur(0);
    }

    40% {
        filter: blur(0);
    }

    100% {
        filter: blur(4px);
    }
}
