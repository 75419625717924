.App {
    max-width: 420px;
    margin: 0 auto;
    background-position: 0 -23px;
    background-size: 100%;
    border-top: none;
}

.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    color: white;
    font-size: calc(10px + 2vmin);
    background-color: #282c34;
}

.App-link {
    color: #61dafb;
}
