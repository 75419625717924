.qr-scanner {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    max-height: 100%;
    margin: 0;
    padding: 0;
    background-color: black;

    &__video-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        overflow: hidden;
    }

    &__video {
        flex-shrink: 0;
        width: 100%;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
    }

    &__hud {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 18px;
    }

    &__center-space-row {
        flex: 10 0;
    }

    &__message {
        flex: 0 0;
    }

    &__bottom-space-row {
        flex: 2 0;
    }

    &__button-row {
        display: flex;
        flex: 0 0;
        flex-flow: row nowrap;
        align-content: space-between;
        align-items: center;
        align-self: stretch;
        justify-content: space-between;
    }

    &__delimiter {
        flex: 1 0;
    }

    &__button {
        flex: 0 0;
        margin: 3px;
        padding: 12px;
        font-size: 0.1em;
        line-height: 0.1em;
        background: radial-gradient(rgb(0 0 0 / 35%) 16px, transparent 26px);
        cursor: pointer;
    }
}
