.main {
    padding: 56px 12px 68px;

    &_fullWidth {
        padding-right: 0;
        padding-left: 0;
    }

    &_paddingTop {
        padding-top: 68px; // 56 + 12
    }
}
