.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    padding: 3px 18px;
    color: #fff !important;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.2em;
    text-decoration: none !important;
    background: #6236ff !important;
    border: 2px solid #6236ff;
    border-radius: 100px !important;
    outline: 0;
    box-shadow: none !important;
    cursor: pointer;
    transition: all 0.2s;
    user-select: none;

    &:focus {
        outline: 0;
    }

    &_secondary {
        background: #75879d !important;
        border-color: #75879d !important;
    }

    &_disabled {
        cursor: default;
        opacity: 0.5;
    }
}
