.BillNativeView {
    margin: 0;
    padding: 1em;
    color: #666;
    font-weight: normal;
    line-height: 1.3em;
    background: #fff;
    box-shadow: 1px 1px 2px 1px #00000030;

    &__retailPlace {
        margin-bottom: 0.3em;
        color: #000;
        font-size: 1.5em;
        line-height: 1.2em;
        text-align: center;
    }

    &__billTitle {
        margin-top: 1em;
        color: #000;
        font-size: 1.5em;
        line-height: 1.2em;
        text-align: center;

        span {
            display: inline-block;
        }
    }

    &__status {
        margin-top: 1em;
        color: var(--info);
        font-size: 1.5em;
        line-height: 1.2em;
        text-align: center;

        span {
            display: inline-block;

            &_error {
                color: var(--warning);
            }
        }
    }

    &__error {
        text-align: center;
    }

    &__errorMessage {
        margin-bottom: 0.3em;
        color: #000;
        line-height: 1.2em;
        text-align: center;
    }

    &__errorLastTry {
        display: flex;
        flex-direction: column;
    }

    &__address {
        font-size: 0.8em;
        line-height: 1.4em;
        text-align: center;
    }

    &__center {
        text-align: center;
    }

    &__items {
        margin-top: 0.5em;
        padding: 0;
        list-style: none;
    }

    &__totalSum {
        display: flex;
        margin-top: 1em;
        margin-bottom: 0.5em;
        color: #000;
        font-size: 1.5em;
    }

    &__subSum {
        display: flex;

        &_withSpace {
            margin-bottom: 0.25em;
        }
    }

    &__tagName {
        flex: 1 1 70%;
        text-align: left;

        &_uppercase {
            text-transform: uppercase;
        }
    }

    &__value {
        flex: 1 0 30%;
        white-space: nowrap;
        text-align: right;
    }

    &__tagList {
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 0.5em;

        > div {
            white-space: nowrap;
            text-align: left;
        }

        @media screen and (min-width: 500px) {
            grid-template-columns: 1fr 1fr;

            > div {
                &:nth-child(2n) {
                    text-align: right;
                }
            }
        }
    }
}

.item {
    display: grid;
    grid-template-areas:
        'index name name'
        'empty price sum';
    grid-template-columns: 1.8em 1fr 1fr;
    margin: 0.3em;

    &__index {
        grid-area: index;
        padding-right: 0.5em;
        color: #000;
        text-align: left;
    }

    &__name {
        grid-area: name;
        margin-bottom: 0.2em;
        color: #000;
    }

    &__price {
        grid-area: price;
    }

    &__sum {
        grid-area: sum;
        text-align: right;
    }
}
