// Example box
.exampleBox{
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid darken($colorLine, 10%);
  background: rgba($colorLine, .3);
  font-size: 10px;
}
.rounded{
  border-radius: $borderRadius !important;
}
// Col
.row {
  margin-left: -8px;
  margin-right: -8px;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12{
  padding-left: 8px;
  padding-right: 8px;
}
// Spacing

.m-0 {
  margin: 0 !important;
}
.m-05{
  margin: 5px !important
}
.m-1 {
  margin: 8px !important;
}
.m-2 {
  margin: 16px !important;
}
.m-3 {
  margin: 24px !important;
}
.m-4 {
  margin: 32px !important;
}
.m-5 {
  margin: 40px !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.mt-05 {
  margin-top: 5px !important;
}
.mt-1 {
  margin-top: 8px !important;
}
.mt-2 {
  margin-top: 16px !important;
}
.mt-3 {
  margin-top: 24px !important;
}
.mt-4 {
  margin-top: 32px !important;
}
.mt-5 {
  margin-top: 40px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}
.mb-05 {
  margin-bottom: 5px !important;
}
.mb-1 {
  margin-bottom: 8px !important;
}
.mb-2 {
  margin-bottom: 16px !important;
}
.mb-3 {
  margin-bottom: 24px !important;
}
.mb-4 {
  margin-bottom: 32px !important;
}
.mb-5 {
  margin-bottom: 40px !important;
}

.ml-0 {
  margin-left: 0 !important;
}
.ml-05 {
  margin-left: 5px !important;
}
.ml-1 {
  margin-left: 8px !important;
}
.ml-2 {
  margin-left: 16px !important;
}
.ml-3 {
  margin-left: 24px !important;
}
.ml-4 {
  margin-left: 32px !important;
}
.ml-5 {
  margin-left: 40px !important;
}

.mr-0 {
  margin-right: 0px !important;
}
.mr-05 {
  margin-right: 5px !important;
}
.mr-1 {
  margin-right: 8px !important;
}
.mr-2 {
  margin-right: 16px !important;
}
.mr-3 {
  margin-right: 24px !important;
}
.mr-4 {
  margin-right: 32px !important;
}
.mr-5 {
  margin-right: 40px !important;
}

.p-0 {
  padding: 0 !important;
}
.p-05 {
  padding: 5px !important;
}
.p-1 {
  padding: 8px !important;
}
.p-2 {
  padding: 16px !important;
}
.p-3 {
  padding: 24px !important;
}
.p-4 {
  padding: 32px !important;
}
.p-5 {
  padding: 40px !important;
}

.pt-0 {
  padding-top: 0px !important;
}
.pt-05 {
  padding-top: 5px !important;
}
.pt-1 {
  padding-top: 8px !important;
}
.pt-2 {
  padding-top: 16px !important;
}
.pt-3 {
  padding-top: 24px !important;
}
.pt-4 {
  padding-top: 32px !important;
}
.pt-5 {
  padding-top: 40px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}
.pb-05 {
  padding-bottom: 5px !important;
}
.pb-1 {
  padding-bottom: 8px !important;
}
.pb-2 {
  padding-bottom: 16px !important;
}
.pb-3 {
  padding-bottom: 24px !important;
}
.pb-4 {
  padding-bottom: 32px !important;
}
.pb-5 {
  padding-bottom: 40px !important;
}

.pl-0 {
  padding-left: 0px !important;
}
.pl-05 {
  padding-left: 5px !important;
}
.pl-1 {
  padding-left: 8px !important;
}
.pl-2 {
  padding-left: 16px !important;
}
.pl-3 {
  padding-left: 24px !important;
}
.pl-4 {
  padding-left: 32px !important;
}
.pl-5 {
  padding-left: 40px !important;
}

.pr-0 {
  padding-right: 0px !important;
}
.pr-05 {
  padding-right: 5px !important;
}
.pr-1 {
  padding-right: 8px !important;
}
.pr-2 {
  padding-right: 16px !important;
}
.pr-3 {
  padding-right: 24px !important;
}
.pr-4 {
  padding-right: 32px !important;
}
.pr-5 {
  padding-right: 40px !important;
}
