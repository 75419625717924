body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --black: #050505;
    --dark-gray: #606060;
    --gray: #909090;
    --light-gray: #d0d0d0;
    --bg-gray: #f0f0f0;
    --white: #fff;
    --headerHeight: 36px;
    --footerHeight: 70px;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */ /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.capitalize {
    text-transform: capitalize;
}
