.moneyView {
    display: inline-block;
    white-space: nowrap;

    .fractional {
        font-size: 0.7em;
        opacity: 0.6;

        &::before {
            content: '.';
        }

        &::after {
            content: ' ';
        }
    }
}
