.accordion {
  border-top: 1px solid $colorLine;
  border-bottom: 1px solid $colorLine;
  background: #fff;

  .accordion-content {
    padding: 8px 16px 20px 16px;
  }
  .accordion-header {
    position: relative;
    .btn {
      justify-content: flex-start;
      border-radius: 0 !important;
      width: 100%;
      font-weight: $regular;
      font-size: $fontSize;
      padding: 8px 50px 8px 16px;
      min-height: 50px;
      color: $colorHeading;
      &:active {
        background: rgba($colorLine, 0.3);
      }
      &:after {
        content: "\f3d1";
        font-family: "Ionicons";
        font-size: 18px;
        position: absolute;
        right: 16px;
        color: $colorLight;
        opacity: 0.6;
        line-height: 1em;
        height: 18px;
        top: 50%;
        margin-top: -9px;
        transform: rotate(-90deg);
      }
      &:before {
        content: "";
        display: block;
        height: 1px;
        background: transparent;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
      }
      &.collapsed {
        &:after {
          transform: rotate(90deg);
        }
        &:before {
          background: $colorLine;
        }
      }
      .icon,
      ion-icon{
        width: 24px;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $colorPrimary;
        margin-right: 10px;
      }
      &.btn.btn-primary,
      &.btn.btn-secondary,
      &.btn.btn-success,
      &.btn.btn-danger,
      &.btn.btn-warning,
      &.btn.btn-info,
      &.btn.btn-dark,{
        ion-icon,
        .icon{
          color: #FFF;
        }
        &:after{
          color: #FFF;
        }
        &:before{
          display: none;
        }
      }
    }
  }
  .item:last-child {
    .accordion-header .btn:before {
        display: none;
    }
  }
}

.inset{
    .accordion{
        border-radius: $borderRadius;
        border: 0;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
        overflow: hidden;
    }
}